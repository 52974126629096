import React, { useContext, useEffect } from 'react'
import { Link } from 'gatsby'
import { graphql } from 'gatsby'
import SEO from '../components/SEO'
import Header from '../components/Header'
import Footer from '../components/Footer'
import Button from '../components/Button'
import SliderHome from '../components/SliderHome'
import CardProject from '../components/CardProject'
import SplitText from '../components/SplitText'
import ParallaxImage from '../components/ParallaxImage'
import VideoResponsive from '../components/VideoResponsive'
import SliderScroll from '../components/SliderScroll'
import VideoScroll from '../components/VideoScroll'
import { RichText } from 'prismic-reactjs'
import { ContextLoader } from '../context';
import { detectElementsOnScreen, killObservers } from '../helpers/detectElementsOnScreen'

const Homepage = ({ data }) => {

	const [isLoading] = useContext(ContextLoader);
	useEffect(() => {
		if (isLoading === true) return
		const [observers, elements] = detectElementsOnScreen()
		return () => {
			killObservers(observers, elements)
		}
	}, [isLoading])

  const pageData = data.prismicHomepage.data

	const prismicNavigation = data.prismicNavigation
	const prismicFooter = data.prismicFooter

	const bannerContent = {
		title: pageData.banner_title,
		text: pageData.banner_text,
		buttonText: pageData.banner_button_text,
		buttonLink: pageData.banner_button_link
	}

	const selectedProjects = pageData.selected_projects.map(selectedProject => {
		if (!selectedProject.project.document) return
		const data = selectedProject.project.document.data
		return {
			uid: selectedProject.project.document.uid,
			image: data.image,
			title: data.title,
			location: data.location,
		}
	}).filter(p => p !== undefined)

	const section1 = {
		title: pageData.section_1_title,
		text: pageData.section_1_text
	}

	const section2 = {
		image: pageData.section_2_image,
		title: pageData.section_2_title,
		text: pageData.section_2_text,
		list: pageData.section_2_list,
		bottomImage: pageData.section_2_bottom_image
	}

	const section3 = {
		title: pageData.section_3_title,
		imageLeft: pageData.section_3_image_left,
		text: pageData.section_3_text,
		imageRight: pageData.section_3_image_right,
	}

	const section4Projects = pageData.section_4_projects.map(selectedProject => {
		if (!selectedProject.project.document) return;
		const data = selectedProject.project.document.data;
		return {
			uid: selectedProject.project.document.uid,
			image: data.image,
			title: data.title,
			location: data.location
		}
	}).filter(p => p !== undefined)

	const section4 = {
		title: pageData.section_4_title,
		text: pageData.section_4_text,
		buttonText: pageData.section_4_button_text,
		buttonLink: pageData.section_4_button_link,
		video: pageData.section_4_video,
		videoMobile: pageData.section_4_video_mobile,
		projects: section4Projects.slice(0, 6)
	}

	const section5 = {
		title: pageData.section_5_title,
		text: pageData.section_5_text,
		buttonText: pageData.section_5_button_text,
		buttonLink: pageData.section_5_button_link
	}

	const bottomImage = pageData.bottom_image;

	const pageTitle = "Terracotta Rainscreens - Curtain Walls | Shildan Group";
	const metaDescription = "Our terracotta rainscreens and curtain walls give you the power to transform your building exterior into functional masterpiece.";
	const metaKeywords = "Terracotta Rainscreen,  Terracotta curtain wall";

  return (
    <div>
      <SEO title={pageTitle} description={metaDescription} keywords={metaKeywords} />
			<Header currentPage="homepage" navigation={prismicNavigation} footer={prismicFooter} />

			<section className="Homepage__Banner">
				<h1
					className="Homepage__BannerTitle"
					data-is-on-screen
				>
					<SplitText splitBy="lines" hasMask>
						<RichText render={bannerContent.title.raw} />
					</SplitText>
				</h1>
				<h2
					className="Homepage__BannerText"
					data-is-on-screen
				>
					<SplitText splitBy="words" hasMask>
						<RichText render={bannerContent.text.raw} />
					</SplitText>
				</h2>
				<Button
					link={bannerContent.buttonLink.type}
					className="Homepage__BannerButton"
					modifier="big"
				>
					{bannerContent.buttonText.text}
				</Button>
			</section>

			<SliderHome selectedProjects={selectedProjects} />

			<section className="Homepage__Section1">
				<h2
					className="Homepage__Section1Title"
					data-is-on-screen
				>
					<SplitText splitBy="lines" hasMask>
						<RichText render={section1.title.raw} />
					</SplitText>
				</h2>
				<div
					className="Homepage__Section1Text"
					data-is-on-screen
				>
					<SplitText splitBy="words">
						<RichText render={section1.text.raw} />
					</SplitText>
				</div>
			</section>

			<section className="Homepage__Section2">
				<ParallaxImage
					className="Homepage__Section2Image"
					src={section2.image.fluid.src}
					srcSet={section2.image.fluid.srcSet}
					alt={section2.image.alt}
					sizes="(max-width: 414px) and (min-height: 500px) 100vw, 70vw"
				/>
				<h2
					className="Homepage__Section2Title"
					data-is-on-screen
				>
					<SplitText splitBy="lines" hasMask>
						<RichText render={section2.title.raw} />
					</SplitText>
				</h2>
				<div
					className="Homepage__Section2Text"
					data-is-on-screen
				>
					<SplitText splitBy="words">
						<RichText render={section2.text.raw} />
					</SplitText>
				</div>
				<div className="Homepage__Section2List">
					<SliderScroll speed={1.75} loadAllChildrenOnMobile={true}>
						{section2.list.map((item, index) => (
							<div
								className="Homepage__Section2Item"
								key={`home-section2-list-${index}`}
							>
								<p className="Homepage__Section2ItemIndex">
									{index + 1 < 10 ? `0${index + 1}`: index + 1}
								</p>
								<VideoResponsive
									className="Homepage__Section2ItemVideo"
									srcDesktop={item.video.url}
									srcMobile={item.video_mobile.url}
									autoPlay={true}
									loop={true}
									playsInline={true}
								/>
								<div className="Homepage__Section2ItemInfos">
									<div
										className="Homepage__Section2ItemTitle"
										data-is-on-screen
									>
										<SplitText splitBy="lines" hasMask>
											<RichText render={item.title.raw} />
										</SplitText>
									</div>
									<div
										className="Homepage__Section2ItemText"
										data-is-on-screen
									>
										<SplitText splitBy="words">
											<RichText render={item.text.raw} />
										</SplitText>
									</div>
								</div>
							</div>
						))}
					</SliderScroll>
				</div>
				<ParallaxImage
					className="Homepage__Section2BottomImage"
					src={section2.bottomImage.fluid.src}
					srcSet={section2.bottomImage.fluid.srcSet}
					alt={section2.bottomImage.alt}
					sizes="(max-width: 414px) and (min-height: 500px) 100vw, 70vw"
					linksTo="/projects"
					linkClassName="Homepage__Section2BottomImageLink"
				/>
			</section>

			<section className="Homepage__Section3">
				<h2
					className="Homepage__Section3Title"
					data-is-on-screen
				>
					<SplitText splitBy="lines" hasMask>
						<RichText render={section3.title.raw} />
					</SplitText>
				</h2>
				<div
					className="Homepage__Section3Text"
					data-is-on-screen
				>
					<SplitText splitBy="words">
						<RichText render={section3.text.raw} />
					</SplitText>
				</div>
				<ParallaxImage
					className="Homepage__Section3ImageLeft"
					src={section3.imageLeft.fluid.src}
					srcSet={section3.imageLeft.fluid.srcSet}
					alt={section3.imageLeft.alt}
					sizes="(max-width: 414px) and (min-height: 500px) 100vw, 45vw"
				/>
				<ParallaxImage
					className="Homepage__Section3ImageRight"
					src={section3.imageRight.fluid.src}
					srcSet={section3.imageRight.fluid.srcSet}
					alt={section3.imageRight.alt}
					sizes="(max-width: 414px) and (min-height: 500px) 100vw, 25vw"
				/>
			</section>

			<section className="Homepage__Section4">
				<h2
					className="Homepage__Section4Title"
					data-is-on-screen
				>
					<SplitText splitBy="lines" hasMask>
						<RichText render={section4.title.raw} />
					</SplitText>
				</h2>
				<div
					className="Homepage__Section4Text"
					data-is-on-screen
				>
					<SplitText splitBy="words">
						<RichText render={section4.text.raw} />
					</SplitText>
				</div>
				<Button link={section4.buttonLink.type} className="Homepage__Section4Button" modifier="big">
					{section4.buttonText.text}
				</Button>
				<div className="Homepage__Section4Projects" data-is-on-screen>
					<SliderScroll speed={0.75} loadAllChildrenOnMobile={true}>
						{section4.projects.map((project, index) => (
							<div className="Homepage__Section4Project" key={`section4-projects-${index}`}>
								<CardProject project={project} />
							</div>
						))}
					</SliderScroll>
				</div>
				<div className="VideoScroll Homepage__Section4Video">
					<VideoResponsive
						className="Homepage__Section4Video"
						srcDesktop={section4.video.url}
						srcMobile={section4.videoMobile.url}
						autoPlay={true}
						loop={true}
						playsInline={true}
					/>
				</div>
			</section>

			<section className="Homepage__Section5">
				<h2
					className="Homepage__Section5Title"
					data-is-on-screen
				>
					<SplitText splitBy="lines" hasMask>
						<RichText render={section5.title.raw} />
					</SplitText>
				</h2>
				<div
					className="Homepage__Section5Text"
					data-is-on-screen
				>
					<SplitText splitBy="words">
						<RichText render={section5.text.raw} />
					</SplitText>
				</div>
				<Button link={section5.buttonLink.type} className="Homepage__Section5Button" modifier="big">
					{section5.buttonText.text}
				</Button>
			</section>

			<Footer footer={prismicFooter.data} />
    </div>
  )
}

export const query = graphql`
	query Homepage {
		prismicHomepage {
			data {
				banner_title {
					raw
				}
				banner_text {
					raw
				}
				banner_button_text {
					text
				}
				banner_button_link {
					type
					uid
					url
				}
				selected_projects {
					project {
						document {
							... on PrismicSelectedProject {
								id
								uid
								data {
									image {
										alt
										fluid {
											src
											srcSet
										}
									}
									title {
										raw
									}
								}
							}
						}
					}
				}
				section_1_title {
					raw
				}
				section_1_text {
					raw
				}
				section_2_image {
					alt
					fluid {
						src
						srcSet
					}
				}
				section_2_title {
					raw
				}
				section_2_text {
					raw
				}
				section_2_list {
					video {
						url
					}
					video_mobile {
						url
					}
					title {
						raw
					}
					text {
						raw
					}
				}
				section_2_bottom_image {
					alt
					fluid {
						src
						srcSet
					}
				}
				section_3_title {
					raw
				}
				section_3_image_left {
					alt
					fluid {
						src
						srcSet
					}
				}
				section_3_text {
					raw
				}
				section_3_image_right {
					alt
					fluid {
						src
						srcSet
					}
				}
				section_4_title {
					raw
				}
				section_4_text {
					raw
				}
				section_4_button_text {
					text
				}
				section_4_button_link {
					url
					type
					uid
				}
				section_4_video {
					url
				}
				section_4_video_mobile {
					url
				}
				section_4_projects {
					project {
						document {
							... on PrismicSelectedProject {
								id
								uid
								data {
									image {
										alt
										fluid {
											src
											srcSet
										}
									}
									title {
										text
									}
									location {
										text
									}
								}
							}
						}
					}
				}
				section_5_title {
					raw
				}
				section_5_text {
					raw
				}
				section_5_button_text {
					text
				}
				section_5_button_link {
					type
				}
			}
		}
		prismicNavigation {
      ...HeaderQuery
    }
		prismicFooter {
			...FooterQuery
		}
	}
`

export default Homepage