import React, { useEffect } from 'react'
import { RichText } from 'prismic-reactjs'
import { Link } from 'gatsby'
import ImageSliderSwiperjs from '../components/ImageSliderSwiperjs'
import Picture from './Picture'

const SliderHome = ({ selectedProjects }) => {

	let newSliderData = []
	selectedProjects.forEach(item=>{
		let newSlider = {
			image: item.image.fluid.src, 
			imageSrcSet: item.image.fluid.srcSet, 
			caption: item.title.raw[0]?.text,
			location: item.location?.text,
			uid: item.uid        
		}
		newSliderData.push(newSlider)
	})


	const slidesLength = selectedProjects.length
	let currentIndex = 0
	let canUpdateSlide = true
	let updateSlideTimeout = null

	// Init image
	useEffect(() => {
		document.querySelector('.SliderHome__ImageItem:first-child').classList.add('current')
	}, [])

	// Title and index roller doesn't take their full children height if we don't do that
	useEffect(() => {
		const isMobile = localStorage.getItem('is-touch') === 'true'
		const titleRollerEl = document.querySelector('.SliderHome__SlideTitleRoller')
		const indexRollerEl = document.querySelector('.SliderHome__SlideIndexRoller')
		if (!titleRollerEl) return
		const baseWindowWidth = isMobile ? 375 : 1440
		const childHeightInPx = window.innerWidth * (27 / baseWindowWidth) // same as css height value: ratio(2.7) or ratioM(2.7)
		const newHeightInPx = `${childHeightInPx * slidesLength}px`
		titleRollerEl.style.height = newHeightInPx
		indexRollerEl.style.height = newHeightInPx
	}, [])

	const updateCurrentSlide = dir => {

		if (!canUpdateSlide) return

		canUpdateSlide = false
		updateSlideTimeout = setTimeout(() => {
			canUpdateSlide = true
		}, 1500)

		if (dir === 'prev') {
			if (currentIndex > 0) {
				currentIndex -= 1;
			}
			else {
				currentIndex = slidesLength - 1;
			}
		}
		else if (dir === 'next') {
			if (currentIndex < slidesLength - 1) {
				currentIndex += 1;
			}
			else {
				currentIndex = 0;
			}
		}

		// Update image
		const currentImageEl = document.querySelector('.SliderHome__ImageItem.current');
		currentImageEl.classList.remove('current');
		[...document.querySelectorAll('.SliderHome__ImageItem')][currentIndex].classList.add('current');

		// Update images roller
		const imagesElRect = currentImageEl.getBoundingClientRect();
		const distanceBetweenImages = ((imagesElRect.width + imagesElRect.left) / window.innerWidth) * 100;
		const imagesRollerEl = document.querySelector('.SliderHome__ImagesRoller')
		imagesRollerEl.style.transform = `translate3d(${-distanceBetweenImages * currentIndex}vw, 0, 0)`
		imagesRollerEl.classList.add('sliding')
		setTimeout(() => {
			imagesRollerEl.classList.remove('sliding')
		}, 800);

		// Update index and title
		const indexRollerEl = document.querySelector('.SliderHome__SlideIndexRoller');
		const indexElHeight = indexRollerEl.children[0].offsetHeight;
		const transformIndexEl = `translate3d(0, ${indexElHeight * -currentIndex}px, 0)`;
		indexRollerEl.style.transform = transformIndexEl;

		const titleRollerEl = document.querySelector('.SliderHome__SlideTitleRoller');
		const titleRollerElHeight = titleRollerEl.offsetHeight;
		const transformTitleEl = `translate3d(0, ${-titleRollerElHeight + (indexElHeight * (currentIndex + 1))}px, 0)`;
		titleRollerEl.style.transform = transformTitleEl;
	}

	return (
		<div
			className="SliderHome"
			data-is-on-screen
		>
			<div  className="SliderHome__MobileSWiper">
			<ImageSliderSwiperjs slides={newSliderData}  />
			</div>
			<div className="SliderHome__Images">
				<div className="SliderHome__ImagesEnterWrapper">
					<ul className="SliderHome__ImagesRoller">
						{selectedProjects.map((project, index) => (
							<li
								className="SliderHome__ImageItem"
								key={`home-selected-project-image-${index}`}
							>
								<Picture
									className="SliderHome__Image"
									src={project.image.fluid.src}
									srcSet={project.image.fluid.srcSet}
									alt={project.image.alt}
									sizes="100vw"
									onClickNavigate={`/${project.uid}`}
								/>
							</li>
						))}
					</ul>
				</div>
			</div>
			<div
				className="SliderHome__Infos"
				data-is-on-screen
			>
				<h3 className="SliderHome__Title">
					<span>
						Selected Projects
					</span>
				</h3>
				<div className="SliderHome__SlideIndex">
					<div className="SliderHome__SlideIndexRoller">
						{selectedProjects.map((p, index) => (
							<div
								className="SliderHome__SlideIndexItem"
								key={`home-selected-project-index-${index}`}
							>
								{index + 1}
							</div>
						))}
					</div>
					<span> - </span>
					<div className="SliderHome__SlideIndexMax">
						<span>
							{slidesLength}
						</span>
					</div>
				</div>
				<div className="SliderHome__SlideTitleAppearWrapper">
					<h6 className="SliderHome__SlideTitle">
						<div className="SliderHome__SlideTitleRoller">
							{selectedProjects.map((p, index) => {
								const reversedIndex = selectedProjects.length - index - 1;
								return (
									<div
										className="SliderHome__SlideTitleItem"
										key={`home-selected-project-title-${reversedIndex}`}
									>
										<Link to={selectedProjects[reversedIndex].uid}>
											{RichText.asText(selectedProjects[reversedIndex].title.raw)}
										</Link>
									</div>
								)
							})}
						</div>
					</h6>
				</div>
				<div className="SliderHome__Arrows">
					<svg className="SliderHome__Arrow SliderHome__Arrow--Left" onClick={() => { updateCurrentSlide('prev') }} viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg"><g clipPath="url(#clip0)" stroke="#fff" strokeWidth="1.5"><path d="M20 10.17H2.03M10.29 1.16L1.313 10.17l8.977 9.008"/></g><defs><clipPath id="clip0"><path fill="#fff" transform="rotate(180 10 9.923)" d="M0 0h20v19.354H0z"/></clipPath></defs></svg>
					<svg className="SliderHome__Arrow SliderHome__Arrow--Right" onClick={() => { updateCurrentSlide('next') }} viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg"><g clipPath="url(#clip1)" stroke="#fff" strokeWidth="1.5"><path d="M0 10.17h17.97M9.71 19.179l8.977-9.008L9.71 1.163"/></g><defs><clipPath id="clip1"><path fill="#fff" transform="translate(0 .493)" d="M0 0h20v19.354H0z"/></clipPath></defs></svg>
				</div>
			</div>
		</div>
	)
}

export default SliderHome
